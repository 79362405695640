<template>
  <div class="ui-orders">
    <header class="ui-orders__header">
      <CRow class="align-items-center h-100">
        <CCol sm="auto" class="mt-2 mb-1 my-sm-0">
          <CButton color="outline-primary" size="sm" @click="onCancel()">
            <font-awesome-icon icon="arrow-left" />
          </CButton>
        </CCol>

        <CCol sm="auto" class="mt-2 mb-1 my-sm-0 mr-auto">
          <div class="d-flex align-items-center">
            <figure class="m-0 mr-3" v-if="makertLogoImage()">
              <img
                :src="makertLogoImage()"
                alt=""
                class="border rounded-circle"
                width="50"
                height="50"
              />
            </figure>
            <div class="ui-type-display-lg">Gestione ordini</div>
          </div>
        </CCol>
      </CRow>
    </header>
    <section class="ui-orders__body">
      <div class="ui-orders__wrapper-1" v-if="initialized">
        <OrdersManageFilter />
        <OrdersManageNav />
        <OrdersManageView />
        <OrdersIncidentModal />
        <OrdersIncidentEditModal />
        <OrdersWeightModal />
        <OrdersIncidentMessageModal />
      </div>
    </section>
  </div>
</template>

<script>
import get from "lodash/get";
import {
  OrdersManageFilter,
  OrdersManageNav,
  OrdersManageView,
} from "./partials";
import OrdersIncidentModal from "../share/OrdersIncidentModal";
import OrdersIncidentEditModal from "../share/OrdersIncidentEditModal";
import OrdersWeightModal from "../share/OrdersWeightModal";
import OrdersIncidentMessageModal from "../share/OrdersIncidentMessageModal";

export default {
  name: "OrdersManages",

  data() {
    return {
      initialized: false,
    };
  },

  components: {
    OrdersManageFilter,
    OrdersManageNav,
    OrdersManageView,
    OrdersIncidentModal,
    OrdersWeightModal,
    OrdersIncidentMessageModal,
    OrdersIncidentEditModal,
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.$store.commit("orders/resetState");
      const { view } = this.$route.query;
      if (view) {
        this.$store.commit("orders/setView", view);
      } else {
        this.$router.push({ query: { view: this.$store.state.orders.view } });
      }
      this.initialized = true;
    },

    makertLogoImage() {
      const square = get(this.$store, "state.role.logo_square.image_thumb");
      const rect = get(this.$store, "state.role.logo_rect.image_thumb");
      return square ? square : rect;
    },

    onCancel() {
      this.$router.push({
        name: "RoleProductsList",
        params: { id: this.$store.state.role.id },
      });
    },
  },
};
</script>
